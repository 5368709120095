import React from 'react'
import Layout from '../components/Layout'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <div>
      <h1>Hata. Bu sayfa bulunamadı.</h1>
      <Link href="/">
        <h4>Ana sayfaya dönmek için tıkla!</h4>
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
